import { HeadlineStyle } from "./Headline.style";

interface IHeadline {
  children?: React.ReactNode;
  fontWeight?: "bold" | "light";
  classNames?: string[];
}

const Headline = (props: IHeadline) => {
  const { children, fontWeight, classNames } = props;
  return (
    <HeadlineStyle
      className={classNames && classNames.join("")}
      fontWeight={fontWeight}
    >
      {children && children}
    </HeadlineStyle>
  );
};

export default Headline;
