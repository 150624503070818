import { IUserContextValue, UserType } from "@/core/models/types";
import { useLocalStorage } from "@/hooks/useLocalStorage/useLocalStorage.hook";
import { createContext } from "react";

export const UserContext = createContext<IUserContextValue | null>(null);

type UserProviderPropsType = {
  children: React.ReactNode;
};

const UserProvider: React.FC<UserProviderPropsType> = ({ children }) => {
  const [user, setUser] = useLocalStorage("User");
  const updateUser = async (updatedUser: UserType) => {
    setUser(updatedUser);
  };
  return (
    <UserContext.Provider value={{ user, setUser, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
