import { UserContext } from "@/context/UserContext";
import { IUserContextValue, UserType } from "@/core/models/types";
import { loginWithShopify } from '@/core/services/logIn'
import { useContext, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
// import LoginSocials from "../LoginSocials";
import { FormContainer, SubmitButton } from "./Form.style";
import InputField from "./InputField/InputField";

const LoginForm = () => {
  let navigate = useNavigate();
  const { setUser } = useContext(UserContext) as IUserContextValue;
  const { register, handleSubmit } = useForm();
  const [logInError, setLogInError] = useState<string | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const onSubmit = async (data: FieldValues) => {
    setIsLoggingIn(true);
    setLogInError(null);
    const credentials = {
      email: data.email,
      password: data.password,
    };

    // make an api call to login the user
    const response = await loginWithShopify(
      credentials.email,
      credentials.password
    );

    // if everything went well...
    if (response && response.status === 200) {
      const loggedInUser = await response.data.customer;

      if (loggedInUser && loggedInUser.id) {
        const identifier = loggedInUser.note.split('identifier: ')[1]?.split(',')[0]
        setUser({
          name: loggedInUser.first_name,
          surname: loggedInUser.last_name,
          email: loggedInUser.email,
          uid: loggedInUser.id || '',
          identifier,
        });
        navigate("/avatar");
      } else {
        // we got an unexpected response from the server
        setLogInError("Something went wrong. Please try again.");
      }
    } else {
      if (response && response.status === 401) {
        // invalid login
        setLogInError("Invalid credentials provided, please try again!");
      } else if (response && response.status === 500) {
        // server error
        setLogInError("Something went wrong. Please try again.");
      } else {
        // something went wrong in the app itself
        setLogInError(
          "The site is currently unavailable. Please try again later."
        );
      }
    }
    setIsLoggingIn(false);
  };

  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className="fields">
          <InputField
            name="email"
            label="Email"
            register={register}
            required={true}
          />
          <InputField
            name="password"
            label="Password"
            register={register}
            required={true}
          />
        </div>
        <span>You may also use your Florence By Mills website credentials to sign in</span><br/>
        <a href="https://florencebymills.com/account/login#recover">Forgot your password?</a>
        {logInError && <p>{logInError}</p>}
        <button hidden={true} type="submit"></button>
      </FormContainer>
      <SubmitButton className="form_button" onClick={handleSubmit(onSubmit)}>
        {!isLoggingIn ? <span>Login</span> : <Loader visible={true} />}
      </SubmitButton>
      <p className="paragraph_lg">Or create a new account</p>
      <SubmitButton
        className="form_button second"
        onClick={() => navigate("/signup")}
      >
        Sign Up
      </SubmitButton>
    </>
  );
};

export default LoginForm;
