export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const fullHeight = (pathname: string) => {
  let result = "";
  if (pathname === "/") result = "";
  else {
    if (window.innerHeight < 900) result = "";
    else result = "fullHeight";
  }
  return result;
};


export const isAndroid = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /android/i.test(userAgent)
}

export const isIos = (): boolean => {
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) return true
  const userAgent = navigator.userAgent.toLowerCase()
  return /iPad|iPhone|iPod/i.test(userAgent)
}