import CookieBanner from "@/components/CookieBanner";
import SignUpForm from "@/components/Forms/SignUpForm/SignUpForm";
import Header from "@/components/Header";
import { useLocalStorage } from "@/hooks/useLocalStorage/useLocalStorage.hook";
import styled from "styled-components";

import Headline from "../../components/Headline/Headline";

const Section = styled.section`
  text-align: center;
  > .divider {
    margin: 20px 0;
  }
  > div {
    max-width: ${(props) => props.theme.sizes.formMaxWidth};
    margin: 0 auto;
  }
`;

const SignUp = () => {
  const [showCookieBanner, setShowCookieBanner] = useLocalStorage('cookieBanner')

  return (
    <>
      {showCookieBanner !== true && <CookieBanner setCookie={setShowCookieBanner}/>}
      <Header />
      <Section className="wrapper">
        <div className="padding_h">
          <Headline>Sign up and have fun!</Headline>
          <div className="paragraph_lg">
            Create an account and start to customize your avatar.
          </div>
          <SignUpForm />
          {/*  <div className="divider"></div> */}
        </div>
      </Section>
    </>
  );
};

export default SignUp;
