import Loader from '@/components/Loader/Loader'
import { UserContext } from '@/context/UserContext'
import { IUserContextValue } from '@/core/models/types'
import { signUpNewUser } from '@/core/services/signUpNewUser'
import { useContext, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FormContainer, SubmitButton } from '../Form.style'
import InputField from '../InputField/InputField'
import Select from '../Select'

const SignUpForm = () => {
  let navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [months, setMonths] = useState<string[]>([])
  const [years, setYears] = useState<string[]>([])
  const { setUser } = useContext(UserContext) as IUserContextValue
  const [formServerErrors, setFormServerErrors] = useState<string>('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const onSubmit = async (data: FieldValues) => {

    setIsLoggingIn(true)
    setFormServerErrors('')
    const user = {
      password: data.password,
      email: data.email,
      name: data.name,
      surname: data.surname,
      Birthday: data.month + '/' + data.year,
      privacy_FBM: data.privacy_fbm,
      privacy_GBB: data.privacy_gbb,
    }

    const signedUser = await signUpNewUser(user)

    if (signedUser && signedUser.id) {
      setUser({
        email: signedUser.email,
        name: signedUser.name,
        surname: signedUser.surname,
        uid: signedUser.id,
      })
      navigate('/avatar', { replace: true })
    } else {
      setFormServerErrors(signedUser)
    }
    setIsLoggingIn(false)
  }

  useEffect(() => {
    const mm = []
    for (let i = 1; i <= 12; i++) mm.push(i.toString())
    setMonths(mm)
    const yy = []
    for (let i = 1980; i <= 2000; i++) yy.push(i.toString())
    setYears(yy.reverse())
  }, [])

  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className='name_surname fields'>
          <InputField name='name' label='First Name' register={register} required={true} />
          {errors.name && <span className='error'>Enter a valid name</span>}
          <InputField name='surname' label='Last Name' register={register} required={true} />
          {errors.surname && <span className='error'>Enter a valid surname</span>}
        </div>
        <div className='fields'>
          <InputField name='email' label='Email' register={register} required={true} />
          {errors.email && <span className='error'>Enter a valid email</span>}
          <InputField name='password' label='Password' register={register} required={true} />
          {errors.password && <span className='error'>Password must be at least 6 characters long</span>}
        </div>
        {formServerErrors && <span>{formServerErrors}</span>}
        <div className='fields'>
          <div className='birth_date'>
            <label>Date of Birth</label>
            <Select name='month' label='mm' register={register} required={true} items={months} />
            <Select name='year' label='yy' register={register} required={true} items={years} />
          </div>
          {(errors.month || errors.year) && <span className='error'>Birthdate must be selected</span>}
        </div>
        <div className='privacy'>
          <div className='privacy_item'>
            <input {...register('privacy_gbb')} name='privacy_gbb' type='checkbox' id='privacy_gbb' title='Privacy choice for give back beauty' />
            <label htmlFor='privacy_gbb'>
              By signing up, you consent to the processing of your personal data for marketing purposes by Give Back Beauty LLC in accordance with the{' '}
              <a href='https://florencebymills.com/pages/privacy-policy'>Privacy Policy</a>
            </label>
          </div>
          <div className='privacy_item'>
            <input {...register('privacy_fbm')} type='checkbox' id='privacy_fbm' title='Privacy choice for florence by mills'></input>
            <label htmlFor='privacy_fbm'>
              By signing up, you consent to the processing of your personal data for marketing purposes by Florence Beauty LLC in accordance with the{' '}
              <a href='https://florencebymills.com/pages/privacy-policy'>Privacy Policy</a>
            </label>
          </div>
          <p>
            By signing up you accept our <a href='https://florencebymills.com/pages/terms-and-conditions'>terms and condition</a>
          </p>
          <p>By signing up you will be registered on Florence Beauty LLC website</p>
        </div>
        <button hidden={true} type='submit'></button>
      </FormContainer>
      {/*  <LoginSocials /> */}
      <SubmitButton className='form_button' onClick={handleSubmit(onSubmit)}>
        {!isLoggingIn ? <span>start creating</span> : <Loader visible={true} />}
      </SubmitButton>
      <p className='paragraph_lg'>Or login with your account</p>
      <SubmitButton className='form_button second' onClick={() => navigate('/login')}>
        Login
      </SubmitButton>
    </>
  )
}
export default SignUpForm
