import { devices } from "@/styles/devices";
import styled from "styled-components";

export const FormContainer = styled.form`
  margin: 20px 0 20px 0;
  .name_surname {
    display: flex;
    align-items: center;
    gap: 10%;
    > * {
      flex: 45%;
    }
  }
  .birth_date {
    text-align: left;
    select {
      border: 1px solid ${(props) => props.theme.colors.secondary};
    }
  }

  .privacy {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-size: 14px;
    }

    &_item {
      display: flex;
      flex_direction: row;
      align-items: flex-start;

      input {
        display: grid;
        place-content: center;
        width: 35px;
        height: 30px;
      }

      label{
        font-size: 14px;
      }
    }
  }
  .error {
    color: ${(props) => props.theme.colors.error};
  }
  .default_text {
    margin-top: 20px;
  }
`

export const SubmitButton = styled.button`
  width: 276px;
  height: 62px;
  margin: 40px 0;
  font-size: ${(props) => props.theme.fonts.sm};
  background: ${(props) => props.theme.colors.primary};
  color: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.bold};
  &:focus {
    opacity: 0.7;
  }

  transition: all 0.3s ease-out;
  transition-property: color, opacity, background;

  &:hover {
    background: #ffffff;
    color: ${(props) => props.theme.colors.primary};
  }

  @media ${devices.mobile_xs} {
    width: 90%;
  }
  &.second {
    margin-top: 0;
  }
`;
