import styled from "styled-components";

export const ShareModalContainer = styled.div`
  position: relative;
  .logo_container {
    max-width: 200px;
  }
  input {
    background-image: none !important;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    height: 34px;
    padding: 0 0 0 10px;
    font-family: ${(props) => props.theme.fonts.light};
    width: 70%;
    font-size: 14px;
    outline: none;
  }
  p {
    text-align: center;
  }
  .close_icon {
    position: absolute;
    right: -10px;
    top: -5px;
    cursor: pointer;
  }
`;
