// theme.ts
import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  borderRadius: "25px",
  colors: {
    primary: "#7069BC",
    secondary: "#B4B3E3",
    error: "#ed143d",
  },
  fonts: {
    bold: "CeraProBold, open-sans",
    light: "CeraProLight, open-sans",
    xl: "36px",
    lg: "32px",
    md: "24px",
    sm: "20px",
    xs: "18px",
    xxs: "16px",
  },
  gradient: {
    primary:
      "linear-gradient(#ECD9E3 0%, rgba(255, 255, 255, 1) 52.6%, rgba(204, 196, 233, 1) 90%, #9A94E0 100%)",
    secondary:
      "linear-gradient(to bottom, #fffefe 0%,#fdf6f7 33%,#f8d9de 100%)",
  },
  sizes: {
    maxWidth: "1440px",
    formMaxWidth: "515px",
  },
};
