import { Steps } from "./CreationSteps.style";

export type StepsPropsType = {
  flex: "row" | "column";
};

const CreationSteps = (props: StepsPropsType) => {
  const { flex } = props;
  return (
    <Steps flex={flex}>
      <div className="step">
        <p className="number">1</p>
        <p className="default_text">
          take a selfie<br></br>
          or pick from a selection of pre-made avatars.
        </p>
      </div>
      <div className="step">
        <p className="number">2</p>
        <p className="default_text">
          customize your avatar<br></br>
          choose from 300+ customization options..
        </p>
      </div>
      <div className="step">
        <p className="number">3</p>
        <p className="default_text">
          view your avatar in AR and share with friends!
        </p>
      </div>
    </Steps>
  );
};

export default CreationSteps;
