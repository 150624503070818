import styled from "styled-components";

export const FooterStyled = styled.footer`
  width: ${(props) => props.theme.sizes.formMaxWidth};
  margin: 0 auto;
  padding: 40px 0 0;
  max-width: 90%;
  .socials_row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .copyright {
    font-size: ${(props) => props.theme.fonts.xs};
    color: #ffffff;
    margin: 0;
    font-family: ${(props) => props.theme.fonts.bold};
    text-align: center;
  }
  .divider {
    margin-bottom: 30px;
  }
`;
