import { devices } from "@/styles/devices";
import styled from "styled-components";

export const ViewerStyled = styled.div`
  max-width: 1200px;

  .viewer_container {
    position: relative;
    // Loader
    > div:not(.share) {
      position: absolute;
      left: 0;
      right: 0;
      width: 50px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50px;
    }
    iframe {
      border: none;
      width: 100%;
      height: 500px;

      @media ${devices.mobile} {
        height: 450px;
      }
    }
    + .share {
      max-width: 400px;
      text-align: center;
      margin: 0 auto;
      p {
        color: ${(props) => props.theme.colors.primary};
        font-family: ${(props) => props.theme.fonts.bold};
        font-size: ${(props) => props.theme.fonts.md};

        @media ${devices.mobile} {
          font-size: 16px;
        }
      }
      > button {
        margin: 0 auto;
        display: block;
      }
    }
  }
`
