import { devices } from "@/styles/devices";
import styled from "styled-components";

export const HeaderStyled = styled.header`
  height: 100px;

  @media ${devices.mobile} {
    height: 100px;
  }

  position: relative;
  .header_container {
    position: absolute;
    height: 120px;
    right: 0;
    left: 0;
    display: flex;

    > .link {
      position: absolute;
      right: 0;
      top: 0;
      color: ${(props) => props.theme.colors.primary};
    }
    .logo > img {
      max-width: 300px;

      @media ${devices.mobile} {
        max-width: 180px;
      }

    }

    @media ${devices.mobile} {
      height: 150px;
    }
  }
  .header_options {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    right: 15px;
    top: 10px;
    > * {
      color: ${(props) => props.theme.colors.primary};
      font-family: ${(props) => props.theme.fonts.bold};
      font-size: ${(props) => props.theme.fonts.sm};
      @media ${devices.tablet} {
        font-size: 14px;
      }
    }
  }
`;
