import React from "react";
import { Link } from "react-router-dom";
import { ButtonStyle, IButtonStyle } from "./Button.style";

interface IButton extends IButtonStyle {
  onClick?: () => void;
  children?: React.ReactNode;
  linkTo?: string;
  style?: any;
}

const Button = (props: IButton) => {
  const { size, onClick, children, linkTo, style } = props;
  return (
    <ButtonStyle style={style} size={size} onClick={onClick}>
      {linkTo ? (
        <Link className='link' to={linkTo}>
          {children}
        </Link>
      ) : (
        children
      )}
    </ButtonStyle>
  )
};

export default Button;
