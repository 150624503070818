import { UserContext } from "@/context/UserContext";
import { IUserContextValue } from "@/core/models/types";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import { CardsBannerStyle, Image } from "./CardsBanner.style";
import { isAndroid, isIos } from "@/core/utils";

const CardsBanner = () => {
  const [gridImages, setGridImages] = useState<string[]>([])
  const { user } = useContext(UserContext) as IUserContextValue
  const [isViewerLoaded, setIsViewerLoaded] = useState<boolean>(false)
  const { REACT_APP_3DVIEWER_CLIENT, REACT_APP_3DVIEWER_URL } = process.env
  const viewerRef = useRef<HTMLIFrameElement>(null)
  if (!REACT_APP_3DVIEWER_URL) throw new Error('REACT_APP_3DVIEWER_URL is not defined')
  const viewerUrl = `${REACT_APP_3DVIEWER_URL}?customerId=${REACT_APP_3DVIEWER_CLIENT}&productId=MILLIE-avatar-test4&autoRotate=false`
  const viewerOrigin = REACT_APP_3DVIEWER_URL.substring(0, REACT_APP_3DVIEWER_URL.length - 1)

  useEffect(() => {
    const images = []
    for (let i = 1; i <= 6; i++) images.push(`images/avatar_grid_${i}.png`)
    setGridImages(images)
  }, [])

  // subscribe to events from 3dViewer
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin === viewerOrigin) {
        if (event.data === '3dViewer:Loaded') {
          const zoom = isAndroid() || isIos() ? 1.8 : 1.4

          setIsViewerLoaded(true)
          viewerRef.current?.contentWindow?.postMessage('3dViewer:removeBG', viewerUrl)
          viewerRef.current?.contentWindow?.postMessage('3dViewer:hideAR', viewerUrl)
          viewerRef.current?.contentWindow?.postMessage(`3dViewer:changeZoom:${zoom}`, viewerUrl)
        }
      }
    })
  }, [viewerOrigin, viewerUrl])

  return (
    <CardsBannerStyle className='padding_section'>
      <div className='wrapper'>
        <div className='content padding_h'>
          <div className='col-1'>
            <iframe
              data-testid='viewer_iframe'
              style={{
                opacity: `${isViewerLoaded ? '1' : '0'}`,
                width: '100%',
                height: '500px',
                border: 'none',
              }}
              ref={viewerRef}
              title='Future fasion viewer'
              allowFullScreen
              src={viewerUrl}
            ></iframe>
          </div>
          <div className='col-2'>
            <div className='cards-grid'>
              {gridImages.length ? gridImages.map((image, i) => <Image key={`image` + i} index={i + 1} src={image} />) : null}
              <Button linkTo={user ? '/avatar' : '/login'} size='small'>
                CREATE YOUR OWN
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CardsBannerStyle>
  )
};

export default CardsBanner;
