import styled from "styled-components";

export const FieldWrapper = styled.div`
  input {
    background-image: none !important;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    padding: 5px 0 5px 5px;
    font-family: ${(props) => props.theme.fonts.light};
    width: 100%;
    font-size: ${(props) => props.theme.fonts.sm};
    outline: none;
  }
  label {
    display: block;
  }
`;
