import React from "react";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import Share from "@/pages/Share/Share";
import SignUp from "@/pages/SignUp";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Viewer from "@/pages/Viewer";
import Avatar from "@/pages/Avatar";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/avatar" element={<PrivateRoute element={<Avatar />} />} />
      <Route path="/viewer" element={<PrivateRoute element={<Viewer />} />} />
      <Route path="/public-share" element={<Share />} />
    </Routes>
  );
};

export default MainRoutes;
