import { devices } from "@/styles/devices";
import styled from "styled-components";
import { StepsPropsType } from "./CreationSteps";

export const Steps = styled.div<StepsPropsType>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  justify-content: center;
  gap: 10%;
  @media ${devices.mobile} {
    flex-direction: column;
  }
  .step {
    @media ${devices.mobile} {
      max-width: 240px;
      margin: 0 auto;
    }
    .number {
      font-family: ${(props) => props.theme.fonts.bold};
      font-size: ${(props) => props.theme.fonts.lg};
      color: ${(props) => props.theme.colors.primary};
      margin-bottom: 0;
    }
  }
`;
