import styled from "styled-components";

interface IIconSocial {
  url: string;
  iconType: "facebook" | "twitter" | "instagram" | "twitch" | "youtube";
}

const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

const IconSocial = (props: IIconSocial) => {
  const { url, iconType } = props;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Icon
        src={`/images/${iconType}.svg`}
        alt={iconType}
        aria-hidden="false"
      />
    </a>
  );
};

export default IconSocial;
