import { UserContext } from "@/context/UserContext";
import { IUserContextValue } from "@/core/models/types";

import { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../Logo";
import { HeaderStyled } from "./Header.style";

const Header = () => {
  const location = useLocation();
  // If it is signup or login page don't show Header
  const show =
    location.pathname.match("signup") ||
    location.pathname.match("login") ||
    false;
  const { user, setUser } = useContext(UserContext) as IUserContextValue;
  let navigate = useNavigate();
  const logOut = () => {
    setUser(null);
    navigate("/");
  };

  const getUserName = () => { 
    if (user) {
      return user.name + " " + user.surname;
    }
    return "";
  }

  return (
    <HeaderStyled>
      <div className='header_container'>
        <Logo />
        {!show &&
          (user ? (
            <div className='header_options'>
              {user.identifier && !location.pathname.match('viewer') ? (
                <Link className='link' to='/viewer'>
                  <span>{getUserName()}</span>
                </Link>
              ) : (
                <button style={{ padding: '3px' }} className='link'>
                  {getUserName()}
                </button>
              )}
              <span>|</span>
              <button style={{ padding: '3px' }} className='link' onClick={logOut}>
                Logout
              </button>
            </div>
          ) : (
            <div className='header_options'>
              <Link className='link' to='/login'>
                Login
              </Link>
            </div>
          ))}
      </div>
    </HeaderStyled>
  )
};

export default Header;
