import { FieldValues, UseFormRegister } from "react-hook-form";
import styled from "styled-components";

const Dropdown = styled.select`
  background-color: #ffffff;
  padding: 5px;
  font-family: ${(props) => props.theme.fonts.light};
  > option {
    font-family: ${(props) => props.theme.fonts.light};
  }
`;

interface ISelect {
  label: string;
  items: string[];
  register: UseFormRegister<FieldValues>;
  name: string;
  required: boolean;
}

const Select = (props: ISelect) => {
  const { label, items, register, name, required } = props;

  return (
    <Dropdown defaultValue="" {...register(name, { required })}>
      <option value="">{label}</option>
      {items.length &&
        items.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
    </Dropdown>
  );
};

export default Select;
