import styled from "styled-components";

export const ShareIconsStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;

  > .icon {
    width: 30px;
    height: 25px;
    text-align: center;
    padding: 0px;
    img {
      height: 100%;
    }
  }

  > button {
    cursor: pointer;
    border: none;
    background: transparent;
  }
`;
