import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "@/context/UserContext";
import { IUserContextValue } from "@/core/models/types";

/*
 routeGuard is a helper component which checks whether the user is authenticated or not, and if so,
 if the authenticated user is allowed to navigate to the route.
 Additionally also passes the currentUser prop to all pages.
*/

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { user } = useContext(UserContext) as IUserContextValue;

  return user ? props.element : <Navigate to="/login" />;
};

export default PrivateRoute;
