import AboutUs from "@/components/AboutUs";
import CardsBanner from "@/components/CardsBanner";
import CookieBanner from "@/components/CookieBanner";
import HeroBanner from "@/components/HeroBanner";
import InfoBanner from "@/components/InfoBanner";
import { useLocalStorage } from "@/hooks/useLocalStorage/useLocalStorage.hook";

const Home = () => {
  const [showCookieBanner, setShowCookieBanner] = useLocalStorage('cookieBanner')

  return (
    <>
      {showCookieBanner !== true && <CookieBanner setCookie={setShowCookieBanner}/>}
      <HeroBanner />
      <InfoBanner />
      <CardsBanner />
      <AboutUs />
    </>
  );
};

export default Home;
