import { validateEmail } from "@/core/utils";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { FieldWrapper } from "./InputField.style";

type InputPropsType = {
  label: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  name: string;
};

const InputField = (props: InputPropsType) => {
  const { register, required, label, name } = props;
  const validation = (value: string) => {
    if (name === "email") return validateEmail(value);
    else if (name === "password") return value.length >= 6;
    else return true;
  };
  return (
    <FieldWrapper className="field_wrapper">
      <label>{label}</label>
      <input
        defaultValue=""
        {...register(name, { required, validate: validation })}
        type={name === "password" ? "password" : "text"}
      />
    </FieldWrapper>
  );
};

export default InputField;
