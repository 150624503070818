import styled from "styled-components";

export const AvatarSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  .subtitle {
    max-width: 375px;
    margin: 0 auto;
  }
  iframe {
    border: none;
  }
`;
