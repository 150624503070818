import { Navigate } from "react-router-dom";
import Loader from "@/components/Loader/Loader";
import Button from "@/components/Button/Button";
import { useEffect, useRef, useState } from "react";
import { isAndroid, isIos } from "@/core/utils";
import { ViewerStyled } from "../Viewer/Viewer.style";
import Header from "@/components/Header";

const Share = () => {
  const getIdentifier = () => {
    // get identifier from query string
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("identifier");
  };
  const identifier = getIdentifier();
  const { REACT_APP_3DVIEWER_CLIENT, REACT_APP_3DVIEWER_URL } = process.env;
  if (!REACT_APP_3DVIEWER_URL)
    throw new Error("REACT_APP_3DVIEWER_URL is not defined");
  const viewerRef = useRef<HTMLIFrameElement>(null);
  const [isViewerLoaded, setIsViewerLoaded] = useState(false);
  const viewerUrl = `${REACT_APP_3DVIEWER_URL}?customerId=${REACT_APP_3DVIEWER_CLIENT}&productId=${identifier}&autoRotate=false`;
  const viewerOrigin = REACT_APP_3DVIEWER_URL.substring(
    0,
    REACT_APP_3DVIEWER_URL.length - 1
  );

  // subscribe to events from 3dViewer
  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.origin === viewerOrigin) {
        if (event.data === "3dViewer:Loaded") {
          const zoom = isAndroid() || isIos() ? 1.8 : 1.4
          setIsViewerLoaded(true);
          viewerRef.current?.contentWindow?.postMessage(
            "3dViewer:removeBG",
            viewerUrl
          );
          viewerRef.current?.contentWindow?.postMessage(
            "3dViewer:hideAR",
            viewerUrl
          );
          viewerRef.current?.contentWindow?.postMessage(
            `3dViewer:changeZoom:${zoom}`,
            viewerUrl
          );
        }
      }
    });
  }, [viewerOrigin, viewerUrl]);

  return (
    <>
      <Header />
      <ViewerStyled className='wrapper viewer'>
        {!!identifier ? (
          <>
            <div className='viewer_container padding_h'>
              <iframe
                data-testid='viewer_iframe'
                style={{
                  opacity: `${isViewerLoaded ? '1' : '0'}`,
                }}
                ref={viewerRef}
                title='Future fasion viewer'
                allowFullScreen
                src={viewerUrl}
              ></iframe>
              <Loader visible={!isViewerLoaded} />
            </div>
            <div className='share'>
              <p>Share with your friend!</p>
              <a href={`${process.env.REACT_APP_APP_URL}`}>
                <Button
                  style={{
                    pointerEvents: `${isViewerLoaded ? 'auto' : 'none'}`,
                    opacity: `${isViewerLoaded ? '1' : '0.5'}`,
                  }}
                  size='big'
                >
                  Create your own
                </Button>
              </a>
            </div>
          </>
        ) : (
          <Navigate to='/' />
        )}
      </ViewerStyled>
    </>
  )
};

export default Share;
