import axios from "axios";
import { UserSignUpType } from "../models/types";

export const saveIdentifier = async (identifier: string, uid?: string) => {
  if (!uid) return;
  const { REACT_APP_API_URL: ApiUrl } = process.env;
  const response = await axios(
    `${ApiUrl}shopify-auth?shopify=PUT&id=${uid}&identifier=${identifier}`
  );
  console.log("saveIdentifier Response", response);
};

export const signUpNewUser = async (user: UserSignUpType) => {
  if (!user) return null;
  const { REACT_APP_API_URL: ApiUrl } = process.env;
  const { email, name, surname, Birthday, password, privacy_FBM, privacy_GBB } = user
  try {
    const response = await axios({
      method: 'GET',
      url: `${ApiUrl}shopify-auth?shopify=POST&email=${email}&password=${password}&name=${name}&surname=${surname}&Birthday=${Birthday}&privacy_GBB=${privacy_GBB}&privacy_FBM=${privacy_FBM}`,
      validateStatus: () => true, // do not handle status codes
    })

    if (response.status === 200) {
      return {
        name: response.data.message.customer.first_name,
        surname: response.data.message.customer.last_name,
        email: response.data.message.customer.email,
        id: response.data.message.customer.id,
      };
    } else {
      return response.data.message;
    }
  } catch (e: any) {
    console.log(e);
    return e.response.data.message;
  }
};
