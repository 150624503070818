import Modal from "react-modal";
import { defaultTheme as theme } from "@/styles/theme";
import { ShareModalContainer } from "./ShareModal.style";
import { useEffect, useState } from "react";
import ShareIcons from "./ShareIcons/ShareIcons";
import CloseIcon from "@/images/close_icon.svg";
import Logo from "../Logo";
import { isAndroid, isIos } from '@/core/utils'
const { REACT_APP_APP_URL: baseShareUrl }  = process.env;

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "420px",
    maxHeight: "570px",
    border: "none",
    background: theme.gradient.primary,
  },
};

interface IShareModal {
  modalIsOpen: boolean;
  shareBaseImage: string;
  closeModal: () => void;
  identifier: string | null;
}

const ShareModal = (props: IShareModal) => {
  const [invalidImage, setInvalidImage] = useState(false);
  const { modalIsOpen, closeModal, identifier } = props;
  const avatarLink = `${props.shareBaseImage}-release.png`
  const linkShare = `${baseShareUrl}share/?identifier=${identifier}`;
  const isMobile = isAndroid() || isIos();
  const social = isMobile
    ? ['share', 'tiktok', 'instagram', 'twitter', 'facebook', 'whatsapp', 'download']
    : ['share', 'twitter', 'facebook', 'download']



  useEffect(() => {
    Modal.setAppElement(".viewer");
  }, []);

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customModalStyles}>
      {!invalidImage && (
        <ShareModalContainer>
          <div onClick={closeModal} className='close_icon'>
            <img alt='close' src={CloseIcon} />
          </div>
          <Logo />
          <img
            style={{ width: '300px', marginLeft: '30px' }}
            onError={() => setInvalidImage(true)}
            onLoad={() => setInvalidImage(false)}
            src={avatarLink}
            alt='share avatar'
          />
          <ShareIcons urlShare={linkShare} types={social} shareBaseImage={props.shareBaseImage} />
        </ShareModalContainer>
      )}
      {invalidImage && (
        <div>
          <div style={{ textAlign: 'end' }} onClick={closeModal} className='close_icon'>
            <img alt='close' src={CloseIcon} />
          </div>
          <br />
          <span>Image sharing is not yet available, please try again in a few seconds...</span>
        </div>
      )}
    </Modal>
  )
};

export default ShareModal;
