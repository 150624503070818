import LogoImg from "@/images/FlorenceLogo_Horiz.png";
import { devices } from "@/styles/devices";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LogoStyle = styled.div`
  max-width: 372px;
  margin: 0 auto;

  @media ${devices.tablet} {
    padding-top: 20px;
  }
  img {
    width: 100%;
    @media ${devices.tablet} {
      max-width: 300px;
    }
  }
`;

const Logo = () => {
  return (
    <LogoStyle className="logo_container">
      <Link to="/" className="logo">
        <img src={LogoImg} alt="Florence By mills Logo" />
      </Link>
    </LogoStyle>
  );
};

export default Logo;
