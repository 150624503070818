import styled from "styled-components";
import IconSocial from "../IconSocial/IconSocial";
import { FooterStyled } from "./Footer.style";

const Footer = () => {
  return (
    <FooterStyled className="footer">
      <div className="divider"></div>
      <div className="socials_row">
        <IconSocial
          url="https://twitter.com/Florencebymills"
          iconType="twitter"
        />
        <IconSocial
          url="https://www.facebook.com/florencebymills/"
          iconType="facebook"
        />
        <IconSocial
          url="https://www.instagram.com/florencebymills/"
          iconType="instagram"
        />
        <IconSocial
          url="https://www.tiktok.com/login?redirect_url=https%3A%2F%2Fwww.tiktok.com%2F%40florencebymills&lang=en&enter_method=mandatory"
          iconType="twitch"
        />
        <IconSocial
          url="https://www.youtube.com/channel/UC1VQ1g02oC9T0Nc5mXidmiQ"
          iconType="youtube"
        />
      </div>
      <p className="copyright">©Give Back Beauty, LLC 2022</p>
    </FooterStyled>
  );
};

export default Footer;
