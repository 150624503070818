import styled from "styled-components";
import Headline from "../Headline/Headline";
import Avatars from "@/images/full_body_copia.png";
import Button from "../Button/Button";
import { devices } from "@/styles/devices";
import Header from "../Header";
import { useContext } from "react";
import { UserContext } from "@/context/UserContext";
import { IUserContextValue } from "@/core/models/types";

const Background = styled.div`
  background: ${(props) => props.theme.gradient.primary};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media ${devices.tablet} {
    flex-direction: column;
    text-align: center;
  }
`;

const SubHeadline = styled.p`
  font-size: ${(props) => props.theme.fonts.lg};
  color: ${(props) => props.theme.colors.primary};
  line-height: 40px;
  font-family: ${(props) => props.theme.fonts.light};
`;

const Col1 = styled.div`
  flex: 30%;
  align-self: flex-start;
  z-index: 1;
`

const Col2 = styled.div`
  flex: 70%;
  > img {
    width: 100%;
    display: block;
    @media ${devices.tablet} {
      width: 105%;
      margin-left: -26px;
      margin-top: 40px;
    }
  }
`;

const HeroBanner = () => {
  const { user } = useContext(UserContext) as IUserContextValue;
  return (
    <Background>
      <Header />
      <div className="wrapper">
        <FlexWrapper className="padding_h">
          <Col1>
            <Headline>define beauty on our own terms!</Headline>
            <SubHeadline>
              create you own unique <span className="bold">3D avatar </span>
              and join the digital flo fam with
              <span className="bold"> Millie Bobby Brown </span>
            </SubHeadline>
            <Button size="big" linkTo={user ? "/avatar" : "/login"}>
              CREATE NOW
            </Button>
          </Col1>
          <Col2>
            <img src={Avatars} alt="avatar image" aria-hidden="true" />
          </Col2>
        </FlexWrapper>
      </div>
    </Background>
  );
};

export default HeroBanner;
