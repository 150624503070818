import { CookiePolicy } from "./CookieBanner.styles";

export type CookieBannerProps = {
  setCookie: (value: boolean) => void
}

const CookieBanner = (props: CookieBannerProps) => {
  
  return (
    <CookiePolicy>
      <div className='overlay' onClick={() => props.setCookie(true)}></div>
      <div className='content'>
        <div className='text'>
          <span>
            We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and
            analyze website traffic. By clicking “Accept,“ you agree to our website's cookie use as described in our{' '}
            <a href='https://florencebymills.com/pages/cookie-policy'>Cookie Policy</a>.
          </span>
        </div>
        <div className='action'>
          <button onClick={() => props.setCookie(true)}>{'Accept'}</button>
        </div>
      </div>
    </CookiePolicy>
  ) 
}

export default CookieBanner