import styled from "styled-components";

export const CookiePolicy = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 999;
  .overlay {
    background: transparent;
    height: 100vh;
    width: 100vw;
  }
  .content {
    /*  color: white;
    background-color: black; */
    color: #000000;
    background-color: #ffffff;
    margin: 0 40px 20px 20px;
    padding: 2.1875em;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 30px;

    @media screen and (max-width: 58em) {
      flex-direction: column;
      margin: 0;
      gap: 10px;
    }

    .text {
      width: 90%;
      @media screen and (max-width: 58em) {
        width: 100%;
      }

      span {
        font-family: 'Helvetica', 'Arial', sans-serif;
        color: rgb(48, 48, 48);
        font-size: 16px;
      }
    }

    .action {
      button {
        color: #ffffff;
        border: none;
        line-height: 1.2;
        padding: 10px 20px;
        cursor: pointer;
        letter-spacing: 0.105em;
        font-size: 16px;
        text-transform: uppercase;
        background: rgb(167, 164, 226);
        font-family: 'Helvetica', 'Arial', sans-serif;
        border-color: rgb(167, 164, 226);
        border-width: 1px;
        border-radius: 3px;
        border-style: solid;
        padding: 0.625em 2em;
      }
    }
  }
`
