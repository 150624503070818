import CreationSteps from "@/components/CreationSteps";
import Header from "@/components/Header";
import Headline from "@/components/Headline/Headline";
import Loader from "@/components/Loader/Loader";
import { UserContext } from "@/context/UserContext";
import { IUserContextValue } from "@/core/models/types";
import { saveIdentifier } from "@/core/services/signUpNewUser";
import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { AvatarSection } from "./Avatar.style";
import { UserType } from "../../core/models/types";
import { useNavigate } from "react-router-dom";

const Avatar = () => {
  const [isViewerLoading, setIsViewerLoading] = useState(false);
  const [errorState, setErrorState] = useState('');
  const { user, updateUser } = useContext(UserContext) as IUserContextValue;
  const navigate = useNavigate();
  const avatarRef = useRef<HTMLIFrameElement>(null);
  const { REACT_APP_RPM_URL } = process.env;
  const avatarUrl =
    REACT_APP_RPM_URL || "https://ffashion.readyplayer.me/avatar?frameApi";

  const parse = (event: any) => {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  };

  const subscribe = useCallback((event: any) => {
    const json = parse(event);

    if (json?.source !== "readyplayerme") {
      return;
    }

    // Susbribe to all events sent from Ready Player Me once frame is ready
    if (json.eventName === "v1.frame.ready") {
      avatarRef?.current?.contentWindow?.postMessage(
        JSON.stringify({
          target: "readyplayerme",
          type: "subscribe",
          eventName: "v1.**",
        }),
        avatarUrl
      );
    }

    // Get avatar GLB URL
    if (json.eventName === "v1.avatar.exported") {
      onFinish(json.data.url);
    }

    // Get user id
    if (json.eventName === "v1.user.set") {
      console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
    }
  }, []);

  const getNewIdentifier = () => {
    const identifier = uuidv4();
    return identifier;
  };

  const getIdentifier = () => {
    return !!user?.identifier ? user.identifier : getNewIdentifier();
  };

  // once ready player me has finished creating the avatar, convert it into gltf with lambda
  const onFinish = async (url: string) => {
    const { REACT_APP_API_URL } = process.env;
    const apiUrl =
      REACT_APP_API_URL;
    if (!apiUrl) throw new Error("API URL not found");
    setIsViewerLoading(true);
    setErrorState('');
    const userIdentifier = getIdentifier();

    try {
      const response = await axios({
        method: "post",
        url: apiUrl + "glbToGltf",
        validateStatus: () => true,
        data: {
          model: url,
          modelName: userIdentifier,
          Client: "Florence",
        },
      });
      if (response.status === 200) {
        setIsViewerLoading(false);
        saveIdentifier(userIdentifier, user?.uid)
        const updatedUser = { ...user, identifier: userIdentifier } as UserType
        updateUser(updatedUser)
        navigate("/viewer");
      } else {
        setIsViewerLoading(false)
        setErrorState('Something went wrong, please try again later');
      }
    } catch (error) { 
       setIsViewerLoading(false)
       setErrorState('Something went wrong, please try again later')
    }
  };

  // subscribe to events from ready player me
  useEffect(() => {
    if (avatarRef.current) {
      window.addEventListener("message", subscribe);
      document.addEventListener("message", subscribe);
    }
    return () => {
      window.removeEventListener("message", subscribe);
      document.removeEventListener("message", subscribe);
    };
  }, [avatarRef, subscribe]);

  useEffect(() => {
    // check if the user already has an avatar, then redirect to viewer
    if (!!user?.identifier) {
      navigate("/viewer");
    }
  }, []);

  return (
    <>
      <Header />
      <AvatarSection data-testid='avatar_section' className='wrapper'>
        <div className='padding_h' style={{marginTop: '15px'}}>
          <Headline>Your unique avatar</Headline>
          <p className='subtitle default_text'>Create your custom avatar with a selfie. Customize it with hundreds of options.</p>
          <CreationSteps flex='row' />
          <Loader visible={isViewerLoading} />
          {!isViewerLoading && (
            <iframe
              style={{ width: '100%', height: '650px' }}
              ref={avatarRef}
              title='Ready player me'
              className='frame'
              allow='camera *; microphone *; clipboard-write'
              src={avatarUrl}
            ></iframe>
          )}
          {errorState && <p className='error'>{errorState}</p>}
        </div>
      </AvatarSection>
    </>
  )
};

export default Avatar;
