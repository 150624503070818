import styled from "styled-components";
import Button from "../Button/Button";
import Headline from "../Headline/Headline";
import image from "@/images/Flo-Queen.png";

const Background = styled.div`
  button {
    background: transparent;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Image = styled.img`
  max-width: 350px;
`;

const AboutUs = () => {
  return (
    <Background>
      <div className="wrapper">
        <div className="text_container padding_section">
          <Headline>about us</Headline>
          <div className="default_text">
            <p>
              florence by mills is a clean, vegan, and cruelty-free beauty brand
              made with love by actress Millie Bobby Brown.
            </p>
            <p>
              florence is beauty on your own terms — no rules, no struggle
              toward perfection, and no boring beauty standards. Just us,
              playing with how we want to look, feel, and live.
            </p>
            <p>
              florence is here to help with products that are super easy and
              always fun. Millie Bobby Brown made florence to create better
              options for you and your friends.
            </p>
          </div>
          <div className="flex_container">
            <a href="https://florencebymills.com/collections/bestsellers">
              <Button size="small" onClick={() => null}>
                Shop Best Sellers
              </Button>
            </a>

            <a href="https://florencebymills.com/pages/contact-us">
              <Button size="small" onClick={() => null}>
                Contact Us
              </Button>
            </a>
          </div>
        </div>
        <Image src={image} />
      </div>
    </Background>
  );
};

export default AboutUs;
