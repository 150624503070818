import axios from "axios";

export const getIdentifierFromNote = (note: string) => {
  const IdentifierNote = note.split(",")[1];
  if (IdentifierNote && IdentifierNote.length) {
    return IdentifierNote.replace("identifier: ", "");
  } else return null;
};

export const loginWithShopify = async (email: string, password: string) => {
  const { REACT_APP_API_URL: ApiUrl } = process.env;
  try {
    return await axios({
      method: "GET",
      url: `${ApiUrl}shopify-auth?shopify=GET&email=${email}&password=${password}`,
      validateStatus: () => true, // do not handle status codes
    });
  } catch (e: any) {
    console.log("Something went wrong... ", e);
  }
};
