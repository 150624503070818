import { useEffect, useState } from "react";
import { ShareIconsStyled } from "./ShareIcons.style";

const generateShareUrl = (type: string, urlShare: string) => {
  switch (type) {
    case "facebook":
      return `https://www.facebook.com/sharer/sharer.php?u=${urlShare}`;
    case "twitter":
      return `https://twitter.com/intent/tweet?text=${urlShare}`
    case "whatsapp":
      return `whatsapp://send?text=${urlShare}`;
    case "instagram":
      return ``;
    case "twitch":
      return ``;
    case "download":
      return ``;
    case "snapchat":
      return ``;
    case "share":
      return ``;
    case "tiktok":
      return ``;
  }
};

const onShare = async (type: string, urlShare: string, videoBlob: Blob | null, imageBlob: Blob | null) => {
  // snapchat sharing is handled by sdk
  if (type === 'snapchat') return


  const text = 'Check out my Florence by Mills 3D avatar and create your own to join the flo fam!'
  const title = 'My flo avatar'
  if (type === 'share') {
    navigator.share({
      title,
      text,
      url: urlShare,
    })
    return
  }

  if (type === 'tiktok') {
    if (videoBlob) {
      navigator.share({
        title,
        text,
        files: [
          new File([videoBlob], 'select_tiktok.mp4', {
            type: 'video/mp4',
          }),
        ],
      })
    }
    return
  }

  if (type === 'instagram') {
    if (imageBlob) {
      await navigator.share({
        title,
        text,
        files: [
          new File([imageBlob], 'select_instagram.png', {
            type: 'image/png',
          }),
        ],
      })
    }
    return
  }

  // if type is download, download the image
  if (type === 'download' && imageBlob) {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(imageBlob)
    link.download = 'avatar.png'
    link.click()
  }

  // otherwise open url in new tab
  else {
    window.open(generateShareUrl(type, urlShare), '_blank')
  }
}

interface IShareIcons {
  types: string[];
  urlShare: string;
  shareBaseImage: string;
}

const ShareIcons = (props: IShareIcons) => {
  const { types, urlShare } = props;
  const [isSnapChatInitialized, setIsSnapChatInitialized] = useState(false);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  useEffect(() => {
    if (!videoBlob) {
      fetch(`${props.shareBaseImage}.mp4`)
        .then((response) => response.blob())
        .then((blob) => {
          setVideoBlob(blob)
        })
    }

    if (!imageBlob) {
      fetch(`${props.shareBaseImage}_md.png`)
        .then((response) => response.blob())
        .then((blob) => {
          setImageBlob(blob)
        })
    }
  }, [videoBlob, imageBlob, props.shareBaseImage])
  

  useEffect(() => {
    if (!isSnapChatInitialized) {
      const doc = window as any
      const fn = function () {
        if (isSnapChatInitialized) return
        doc.snap.creativekit.initalizeShareButtons(document.getElementsByClassName('snapchat-share-button'))
        setIsSnapChatInitialized(true)
      }
      fn()
      doc.snapKitInit = fn
    }
  }, [isSnapChatInitialized])

  return (
    <ShareIconsStyled>
      {!!types.length &&
        types.map((type) => (
          <button
            key={type}
            className={`${type === 'snapchat' ? 'icon snapchat-share-button' : 'icon'}`} // snapchat-share-button is a class from the snapchat sdk
            onClick={() => onShare(type, urlShare, videoBlob, imageBlob)}
            title={type}
            style={type === 'facebook' ? { width: '20px' } : {}} // facebook icon is too small
            {...(type === 'snapchat' ? { 'data-share-url': urlShare } : {})}
          >
            <img src={`/images/${type}_primary.svg`} aria-hidden={false} alt={`${type} share icon`} />
          </button>
        ))}
    </ShareIconsStyled>
  )
};

export default ShareIcons;
