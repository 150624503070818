import { devices } from "@/styles/devices";
import styled from "styled-components";

export const Info = styled.div`
  position: relative;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
  }
  h2 {
    @media ${devices.tablet} {
      font-size: ${(props) => props.theme.fonts.lg};
    }
  }
  .divider {
    margin-top: 0;
  }
  p {
    &.top {
      font-family: ${(props) => props.theme.fonts.bold};
      font-size: ${(props) => props.theme.fonts.xl};
      margin-top: 0;
      .black {
        color: #000000;
      }
      .light {
        font-family: ${(props) => props.theme.fonts.light};
        color: #000000;
        font-size: ${(props) => props.theme.fonts.lg};
      }
      .primary {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
  .img_avatar {
    position: absolute;
    bottom: 0;
    left: -53px;
    max-width: 30vw;
    @media ${devices.tablet} {
      max-width: 60vw;
      margin: 0 auto;
      position: static;
    }
  }
  .steps_title {
    padding-top: 50px;
  }
`;
