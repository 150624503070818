import { devices } from "@/styles/devices";
import styled from "styled-components";

export interface IButtonStyle {
  size: "big" | "small";
}

export const ButtonStyle = styled.button<IButtonStyle>`
  border: 1px solid ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.bold};

  transition: all 0.3s ease-out;

  ${(props) => {
    switch (props.size) {
      case "big":
        return `
                padding: 15px 25px;
                font-size: ${props.theme.fonts.md};
                background: ${props.theme.colors.primary};
                color: #ffffff;
                &:hover {
                    background: #ffffff;
                    color: ${props.theme.colors.primary};
                }

                @media ${devices.mobile} {
                  padding: 10px 20px;
                }
                `
      case "small":
      default:
        return `
                width: 235px;
                height: 52px;
                font-size: ${props.theme.fonts.xxs};
                background: #ffffff;
                color: ${props.theme.colors.primary};
                &:hover {
                    background: ${props.theme.colors.primary};
                    color: #ffffff;
                }
                `;
    }
  }}
  @media ${devices.mobile_xs} {
    width: 90%;
  }
  > .link {
    width: 100%;
    height: 100%;
  }
`;
