import styled from "styled-components";

export type HeadlineStyleProps = {
  fontWeight?: "bold" | "light";
};

export const HeadlineStyle = styled.h2<HeadlineStyleProps>`
  font-size: ${(props) => props.theme.fonts.xl};
  color: ${(props) => props.theme.colors.primary};
  line-height: 96%;
  margin: 0 0 30px 0;
  ${(props) => {
    switch (props.fontWeight) {
      case "light":
        return `
            font-family: ${props.theme.fonts.light}
            `;
      case "bold":
      default:
        return `
            font-family: ${props.theme.fonts.bold}
            `;
    }
  }}
`;
