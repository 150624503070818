import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { IUserContextValue, UserType } from "@/core/models/types";
import { UserContext } from "@/context/UserContext";
import { Navigate, useNavigate } from 'react-router-dom';
import Loader from "@/components/Loader/Loader";
import { ViewerStyled } from "./Viewer.style";
import Header from "@/components/Header";
import Button from "@/components/Button/Button";
import ShareModal from "@/components/ShareModal";
import { isAndroid, isIos } from '../../core/utils';
import { saveIdentifier } from "@/core/services/signUpNewUser";

const Viewer = () => {
  const {
    REACT_APP_3DVIEWER_CLIENT,
    REACT_APP_3DVIEWER_URL,
    REACT_APP_AVTAR_IMAGE_BASE_URL: baseUrlShareImage,
  } = process.env;
  if (!REACT_APP_3DVIEWER_URL)
    throw new Error("REACT_APP_3DVIEWER_URL is not defined");
  const viewerRef = useRef<HTMLIFrameElement>(null);
  const [isViewerLoaded, setIsViewerLoaded] = useState(false);
  const { user, updateUser } = useContext(UserContext) as IUserContextValue
  const navigate = useNavigate();
  const viewerUrl = `${REACT_APP_3DVIEWER_URL}?customerId=${REACT_APP_3DVIEWER_CLIENT}&productId=${user?.identifier}&autoRotate=false`;
  const viewerOrigin = REACT_APP_3DVIEWER_URL.substring(
    0,
    REACT_APP_3DVIEWER_URL.length - 1
  );
  
  const [errorState, setErrorState] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const shareBaseImage = `${baseUrlShareImage}${user?.identifier}/${user?.identifier}`

  // request share image from the viewer
  const requestShareImage = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  

  // subscribe to events from 3dViewer
  useEffect(() => {

    const setErrorStateTimer = () => {
      if (user?.identifier === '' || user?.identifier === undefined || isViewerLoaded) return

      setErrorState('Something went wrong while trying to load your avatar. Please create a new avatar, redirecting...')
      setTimeout(() => {
        saveIdentifier('', user?.uid)
        const updatedUser = { ...user, identifier: '' } as UserType
        updateUser(updatedUser)
        navigate('/viewer')
      }, 5000)
    }

    const timer = setTimeout(setErrorStateTimer, 12000)
    setErrorState('')
    window.addEventListener("message", (event) => {
      if (event.origin === viewerOrigin) {
        if (event.data === "3dViewer:Loaded") {

          const zoom = (isAndroid() || isIos()) ? 1.8 : 1.8;
          setTimeout(() => {
            setIsViewerLoaded(true)
          }, 1000);
          clearTimeout(timer)
          viewerRef.current?.contentWindow?.postMessage(
            "3dViewer:removeBG",
            viewerUrl
          );
          viewerRef.current?.contentWindow?.postMessage(
            `3dViewer:changeZoom:${zoom}`,
            viewerUrl
          );
        }
      }
    });
  }, [viewerOrigin, viewerUrl]);
  return (
    <>
      <Header />
      <ViewerStyled className='wrapper viewer'>
        {user?.identifier ? (
          <>
            <div className='viewer_container padding_h'>
              {errorState && <p>{errorState}</p>}
              <iframe
                data-testid='viewer_iframe'
                style={{
                  opacity: `${isViewerLoaded ? '1' : '0'}`,
                }}
                ref={viewerRef}
                title='Future fasion viewer'
                allowFullScreen
                src={viewerUrl}
              ></iframe>
              <Loader visible={!isViewerLoaded && errorState.length === 0} />
            </div>
            <div className='share'>
              <p>Share with your friend!</p>
              <Button
                style={{
                  width: '285px',
                  pointerEvents: `${isViewerLoaded && errorState.length === 0 ? 'auto' : 'none'}`,
                  opacity: `${isViewerLoaded && errorState.length === 0 ? '1' : '0.5'}`,
                }}
                size='big'
                onClick={requestShareImage}
              >
                Go social
              </Button>
            </div>
            <ShareModal
              modalIsOpen={modalIsOpen}
              shareBaseImage={shareBaseImage}
              closeModal={() => setModalIsOpen(false)}
              identifier={user?.identifier}
            />
          </>
        ) : (
          <Navigate to='/avatar' />
        )}
      </ViewerStyled>
    </>
  )
};

export default Viewer;
