import { LoaderStyle } from "./Loader.style";

interface ILoader {
  visible: boolean;
}

const Loader = (props: ILoader) => {
  const { visible } = props;

  return visible ? (
    <LoaderStyle className="loader">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoaderStyle>
  ) : null;
};

export default Loader;
