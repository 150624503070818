import MainRoutes from "@/routes/routes";
import Footer from "./components/Footer";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { fullHeight } from "./core/utils";

type FbmContainerType = {
  path: string;
};

const FbmContainer = styled.main<FbmContainerType>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: ${(props) => props.theme.gradient.primary};
  &.home {
    background: ${(props) => props.theme.gradient.secondary};
    min-height: 100vh;
    overflow: auto;
  }
  &.fullHeight {
    height: 100vh;
    overflow: hidden;
  }
`;

function App() {
  const location = useLocation();
  const [isFullHeight, setIsFullHeight] = useState("");
  const [isHomePage, setIsHomePage] = useState("");

  useEffect(() => {
    location && setIsFullHeight(fullHeight(location.pathname));
    setIsHomePage(location.pathname === "/" ? "home" : "");
  }, [location]);


  return (
    <FbmContainer path={location.pathname} className={`fbm_app ${isHomePage} ${isFullHeight}`}>
      <MainRoutes />
      <Footer />
    </FbmContainer>
  )
}

export default App;
