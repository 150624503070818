import { createGlobalStyle } from "styled-components";
import divider from "@/images/divider.svg";

import { devices } from "./devices";
export default createGlobalStyle`
  .fullHeight {
    header {
      height: auto;
    }
    .header_container {
      position: static;
      height: auto;
      .logo {
        display: block;
        text-align: center;
      }
    }
    .default_text {
      margin: 0 0 10px 0;
      line-height: 20px;
      &.subtitle {
        margin: 0 auto 10px auto;
      }
    }

    

    .step {
      .number {
        font-size: ${(props) => props.theme.fonts.sm} !important;
        margin-top: 0;
      }
    }
    iframe {
      height: 50vh !important;
    }
    .viewer {
      iframe {
        height: 55vh !important;
      } 
    }
    footer {
      padding: 10px 0 0;
      .divider {
        margin: 5px 0 15px 0;
      }
    }
    .form_button {
      width: 260px;
      height: 50px;
      margin-bottom: 10px;
      .loader {
        .lds-ring {
          width: 40px;
          height: 40px;
        }div {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .flex_container {

      
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      @media ${devices.tablet} {
        flex-direction: column;
      }

    }
  .home {
    .footer {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      .divider {
        display: none;
      }
    }
    
  }
  
  
  .link {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
  }
  button {
    cursor: pointer;
  }
  label {
    display: block;
    padding: 5px 0;
    text-align: left;
  }
  .wrapper {
    max-width:  ${(props) => props.theme.sizes.maxWidth};
    margin: 0 auto;
    width: 100%;
  }
  .padding_h {
    padding-left: 25px;
    padding-right: 25px;
  }
  .padding_section {
    margin-top: 110px;
  }
  .default_text {
    color: #000000;
    font-size: ${(props) => props.theme.fonts.xxs};
    font-family: ${(props) => props.theme.fonts.light};
    line-height: 29px;
    margin: 0 0 40px 0;
  }
  .divider {
    height: 10px;
    margin: 40px 0 20px;
    width: 100%;
    background: url(${divider});
    @media ${devices.tablet} {
        height: 5px;
      }
  }
  .text_container {
    max-width: 50%;
    @media ${devices.tablet} {
      max-width: 80%;
    }
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 20px;
  }
  .bold {
    font-family: ${(props) => props.theme.fonts.bold}
  }
  .paragraph {
        color: #000000;
        font-size: ${(props) => props.theme.fonts.xs};
        &_lg {
            font-size: ${(props) => props.theme.fonts.sm};
           
        }
    }
`
