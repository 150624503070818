import LoginForm from "@/components/Forms/LoginForm";
import Header from "@/components/Header";
import Headline from "@/components/Headline/Headline";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from '@/context/UserContext';
import { IUserContextValue } from "@/core/models/types";
import { useLocalStorage } from "@/hooks/useLocalStorage/useLocalStorage.hook";
import CookieBanner from "@/components/CookieBanner";

const Section = styled.section`
  padding: 40px 0;
  text-align: center;
  > div {
    max-width: ${(props) => props.theme.sizes.formMaxWidth};
    margin: 0 auto auto auto;
  }
`;

const Login = () => {
  const { user } = useContext(UserContext) as IUserContextValue
  const [showCookieBanner, setShowCookieBanner] = useLocalStorage('cookieBanner')
  const navigate = useNavigate()

  // check if the usr is already logged in, then redirect to viewer
  useEffect(() => { 
    if (!!user?.identifier) {
      navigate("/avatar");
    }
  })

  return (
    <>
      {showCookieBanner !== true && <CookieBanner setCookie={setShowCookieBanner} />}
      <Header />
      <Section className='wrapper'>
        <div className='padding_h'>
          <Headline>login and have fun!</Headline>
          <div className='paragraph_lg'>enter and play with your customized avatar</div>
          <LoginForm />
        </div>
      </Section>
    </>
  )
};

export default Login;
