import imageAvatar from "@/images/ReadyPlayerMe-Avatar.png";
import Headline from "../Headline/Headline";
import Button from "../Button/Button";
import CreationSteps from "../CreationSteps";
import { Info } from "./InfoBanner.style";
import { useContext } from "react";
import { UserContext } from "@/context/UserContext";
import { IUserContextValue } from "@/core/models/types";

const InfoBanner = () => {
  const { user } = useContext(UserContext) as IUserContextValue;
  return (
    <div className="wrapper">
      <Info className="padding_h">
        <div className="text_container padding_section">
          <p className="top">
            <span className="black">share and play</span>
            <br></br>
            <span className="light">join the flo fam and</span>
            <br></br>
            <span className="primary">express youself!</span>
            <br></br>
          </p>
          <Headline fontWeight="light">create your unique avatar</Headline>
          <Button size="small" linkTo={user ? "/avatar" : "/login"}>
            CREATE NOW
          </Button>
        </div>
        <img
          className="img_avatar"
          src={imageAvatar}
          alt="Avatar Instructions"
        />
        <div className="divider"></div>
      </Info>
      <Info className="padding_h">
        <div className="text_container">
          <Headline classNames={["steps_title"]}>
            create an avatar<br></br> from a photo
          </Headline>
          <p className="default_text">
            create your custom avatar with a selfie.<br></br> customize it with
            hundreds of options.
          </p>
          <CreationSteps flex="column" />
        </div>
      </Info>
    </div>
  );
};

export default InfoBanner;
