import { devices } from "@/styles/devices";
import styled from "styled-components";

export const CardsBannerStyle = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${devices.tablet} {
      flex-direction: column-reverse;
    }
  }
  .col-1 {
    flex: 30%;
  }
  .col-2 {
    flex: none;
    transform: translateY(-40px);
  }
  p {
    font-family: ${(props) => props.theme.fonts.bold};
    font-size: ${(props) => props.theme.fonts.lg};
    color: #ffffff;
  }
  .cards-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    @media screen and (max-width: 1439px) {
      margin-left: 25%;
    }
    max-width: 45vw;
    @media ${devices.tablet} {
      max-width: 80vw;
      margin-left: auto;
    }
    border-radius: 25px;
    overflow: hidden;
    position: relative;

    > button {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
    }
  }
`;

type ImagePropsType = {
  index: number;
};

export const Image = styled.img<ImagePropsType>`
  height: 15vw;
  @media ${devices.tablet} {
    height: 40vw;
  }
  ${(props) => {
    switch (props.index) {
      case 1:
        return `
                background: #B4DCFE`;
      case 2:
        return `
                background: #9BAEFE`;
      case 3:
        return `
                background: #6164CD`;
      case 4:
        return `
                background: #99ACFE`;
      case 5:
        return `
                background: #F5C6C8`;
      case 6:
        return `
                background: #E0ECB9`;
    }
  }}
`;
